var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"acn-public-job-detail"},[_c('div',{staticClass:"container fluid"},[_c('div',{staticClass:"d-flex"},[(_vm.myApplication.length > 0)?_c('div',{staticClass:"card d-inline-block p-4 mr-2 mb-2"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Application Pending Approval")]),_c('p',[_vm._v("Your current application is pending for specialist's approval.")]),_c('fd-button',{staticClass:"bordered main mt-2",on:{"click":function($event){return _vm.$router.push({ name: 'ACNMyJobApplications' })}}},[_vm._v(" My Job Applications "),_c('i',{staticClass:"fas fa-chevron-right ml-1"})])],1):_vm._e()]),_c('div',{staticClass:"card p-2 mb-2"},[_c('h3',{staticClass:"my-3 mx-2"},[_vm._v("Job Detail")]),_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"mb-2",style:({ fontSize: '18px' })},[_vm._v(" "+_vm._s(_vm._.get(_vm.jobToApply, "acnJobScope.title"))+" ")]),_c('p',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm._.get(_vm.jobToApply, "acnJobScope.description") || "No job description")+" ")]),_c('display-data',{attrs:{"label":"Commission","content":_vm.commission}}),(_vm.isPayByCommission)?_c('display-data',{attrs:{"label":"Estimated Amount","content":_vm.getJobEstimatedAmount({
              listingCommission: _vm.listingCommission,
              specialistCommission: _vm.specialistCommission,
              jobRates: _vm.jobToApply.rates,
              salePrice: _vm._.get(
                _vm.jobToApply,
                'acnGroup.acnQuest.listing.property.salePrice'
              )
            })}}):_vm._e(),_c('display-data',{attrs:{"label":"Remark","content":_vm.jobToApply.remark}})],1)]),_c('div',{staticClass:"card p-2"},[_c('h3',{staticClass:"my-3 mx-2"},[_vm._v("Current ACN")]),_c('div',{staticClass:"bordered-card p-1",style:({ display: 'grid', gap: '8px' })},[_c('JobListItem',{attrs:{"title":"Registrant","user":_vm._.get(_vm.registrantJob, 'user', {})}}),_c('JobListItem',{attrs:{"title":"Specialist","user":_vm._.get(_vm.specialistJob, 'user', {})}}),_c('JobListItem',{attrs:{"title":"Closer","user":_vm._.get(_vm.closerJob, 'user', {})}})],1),_c('div',{staticClass:"p-1 mt-2"},_vm._l((_vm.jobs),function(job){return _c('div',{key:job.id},[(!_vm.isMainPositions(job))?_c('JobListItem',{class:{ highlighted: job.id == _vm.jobToApply.id },attrs:{"title":_vm._.get(job, 'acnJobScope.title'),"user":_vm._.get(job, 'user', {})}}):_vm._e()],1)}),0)]),(_vm.canApply)?_c('fd-button',{staticClass:"main medium fixed round",attrs:{"icon":"fas fa-file-pen"},on:{"click":_vm.applyJob}},[_vm._v(" Apply Job ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }