<template>
  <div class="acn-public-job-detail">
    <div class="container fluid">
      <div class="d-flex">
        <!-- Application -->
        <div
          v-if="myApplication.length > 0"
          class="card d-inline-block p-4 mr-2 mb-2"
        >
          <h5 class="mb-1">Application Pending Approval</h5>
          <p>Your current application is pending for specialist's approval.</p>
          <fd-button
            class="bordered main mt-2"
            @click="$router.push({ name: 'ACNMyJobApplications' })"
          >
            My Job Applications
            <i class="fas fa-chevron-right ml-1"></i>
          </fd-button>
        </div>
      </div>

      <!-- Job Detail -->
      <div class="card p-2 mb-2">
        <h3 class="my-3 mx-2">Job Detail</h3>

        <div class="p-2">
          <div class="mb-2" :style="{ fontSize: '18px' }">
            {{ _.get(jobToApply, "acnJobScope.title") }}
          </div>
          <p class="mb-3">
            {{
              _.get(jobToApply, "acnJobScope.description") ||
              "No job description"
            }}
          </p>

          <display-data label="Commission" :content="commission"></display-data>
          <display-data
            v-if="isPayByCommission"
            label="Estimated Amount"
            :content="
              getJobEstimatedAmount({
                listingCommission: listingCommission,
                specialistCommission: specialistCommission,
                jobRates: jobToApply.rates,
                salePrice: _.get(
                  jobToApply,
                  'acnGroup.acnQuest.listing.property.salePrice'
                )
              })
            "
          ></display-data>
          <display-data
            label="Remark"
            :content="jobToApply.remark"
          ></display-data>
        </div>
      </div>

      <!-- ACN Jobs -->
      <div class="card p-2">
        <h3 class="my-3 mx-2">Current ACN</h3>
        <!-- Fixed Roles -->
        <div class="bordered-card p-1" :style="{ display: 'grid', gap: '8px' }">
          <JobListItem
            title="Registrant"
            :user="_.get(registrantJob, 'user', {})"
          >
          </JobListItem>
          <JobListItem
            title="Specialist"
            :user="_.get(specialistJob, 'user', {})"
          >
          </JobListItem>
          <JobListItem title="Closer" :user="_.get(closerJob, 'user', {})">
          </JobListItem>
        </div>
        <!-- Agent Jobs -->
        <div class="p-1 mt-2">
          <div v-for="job in jobs" :key="job.id">
            <JobListItem
              v-if="!isMainPositions(job)"
              :title="_.get(job, 'acnJobScope.title')"
              :user="_.get(job, 'user', {})"
              :class="{ highlighted: job.id == jobToApply.id }"
            >
            </JobListItem>
          </div>
        </div>
      </div>

      <!--  -->
      <fd-button
        v-if="canApply"
        class="main medium fixed round"
        icon="fas fa-file-pen"
        @click="applyJob"
      >
        Apply Job
      </fd-button>
    </div>
  </div>
</template>

<script>
import { acn as acnAPI } from "@/api";
import { acnPaymentType } from "@/enums";
import useACNRates from "@/modules/ACN/composables/useACNRates";

export default {
  setup() {
    const { getJobCalculatedRate, getJobEstimatedAmount } = useACNRates();
    return { getJobCalculatedRate, getJobEstimatedAmount };
  },
  components: {
    JobListItem: () => import("@/modules/ACN/components/JobListItem"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      jobToApply: {},
      myApplication: [],

      jobs: [],
      isJobsLoading: false
    };
  },
  computed: {
    canApply() {
      let hasHandler = !!this.jobToApply.user;
      let hasApplied = this.myApplication.length > 0;

      return !hasHandler && !hasApplied;
    },
    registrantJob() {
      return this.jobs.find((job) => job.isForRegistrant);
    },
    specialistJob() {
      return this.jobs.find((job) => job.isForSpecialist);
    },
    closerJob() {
      return this.jobs.find((job) => job.isForCloser);
    },
    isPayByCommission() {
      return this.jobToApply.paymentType == acnPaymentType.COMMISSION;
    },
    commission() {
      if (this.isPayByCommission) {
        let rate = this.getJobCalculatedRate(
          this.specialistCommission,
          this.jobToApply.rates
        );
        return `${rate}%`;
      }
      return `Fixed Rate RM${this.jobToApply.rates}`;
    },
    specialistCommission() {
      return this.specialistJob?.rates ?? 0;
    },
    listingCommission() {
      return this.jobToApply.acnGroup.commissionRate;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getJob();
      await this.checkJobIsForNonSpecialist();
      this.getMyApplication();
    },
    checkJobIsForNonSpecialist() {
      if (this.jobToApply.isForSpecialist) {
        this.$router.push({ name: "NotFound" });
      }
    },
    isMainPositions(job) {
      return job.isForRegistrant || job.isForSpecialist || job.isForCloser;
    },
    async getJob() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await acnAPI.getJob(this.$route.params.id);
        this.jobToApply = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
        this.getJobs(this.jobToApply.acnGroup.id);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch job data. Please try again later."
        });
      }
    },
    async getJobs(groupId) {
      try {
        this.isJobsLoading = true;
        let res = await acnAPI.getGroupJobs(groupId);
        this.jobs = this._.cloneDeep(res.data);

        this.isJobsLoading = false;
      } catch (error) {
        this.isJobsLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch ACN Group's Jobs. Please try again later."
        });
      }
    },
    async getMyApplication() {
      try {
        this.$store.commit("setIsLoading", true);
        let res = await acnAPI.getMyJobApplications({
          queries: {
            "acnReward:id": this.jobToApply.id
          }
        });
        this.myApplication = res.data;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch application data. Please try again later."
        });
      }
    },
    async applyJob() {
      let confirm = await this.$confirm({
        title: "Apply For Job",
        message: `Are you sure you want to submit an application for ${this.jobToApply.acnJobScope.title}?`,
        confirmText: "Apply"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.applyJob(this.$route.params.id);

          this.$store.commit("setIsLoading", false);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Applied",
            text:
              "Application has been applied, awaiting specialist's approval."
          });

          this.$router.push({ name: "ACNMyJobApplications" });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to apply for job. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.acn-public-job-detail {
  .highlighted {
    border: solid 2px $color-secondary;
    box-shadow: 0 0 8px rgba($color-secondary, 0.35);
    border-radius: 8px;
  }
}
</style>
